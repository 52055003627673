<template>
  <body class="auth-wrapper container">
    <div class="all-wrapper menu-side with-pattern">
      <div class="auth-form">
        <img alt="logo" class="logo" :src="logo" />
        <h3 class="heading">Resend verification E-mail</h3>
        <a-form
          layout="vertical"
          :model="form"
          :rules="rules"
          name="resend-verification"
          class="user-form"
          @finish="onSubmit"
        >
          <div class="row">
            <div class="col-sm-12">
              <a-form-item name="email" autocomplete="off">
                <a-input v-model:value="form.email" placeholder="Enter email">
                </a-input>
              </a-form-item>
            </div>
          </div>

          <div class="buttons-w mt-2">
            <a-button
              type="primary"
              size="large"
              html-type="submit"
              class="btn-block"
            >
              <span>Resend</span>
            </a-button>
          </div>

          <div class="buttons-w form-link">
            <router-link to="/login">
              <small>Already have a account? Login </small>
            </router-link>
          </div>
        </a-form>
      </div>
    </div>
  </body>
</template>
<script>
import logo from '../../assets/img/assembly-cp-logo.png';
import { mapActions, mapGetters } from 'vuex';
export default {
  mounted() {
    this.form.email = this.getCurrentLogin();
  },
  setup() {
    const rules = {
      email: [
        {
          required: true,
          type: 'email',
          trigger: 'submit',
          message: 'email is required',
        },
      ],
    };
    return { logo, rules };
  },
  computed: {
    ...mapGetters({ currentLogin: 'email' }),
  },
  data() {
    return {
      form: {
        email: this.getCurrentLogin(),
      },
    };
  },
  methods: {
    ...mapActions(['resendVerificationEmail']),
    onSubmit() {
      this.resendVerificationEmail({
        email: this.form.email,
      });
    },
    getCurrentLogin() {
      if (this.currentLogin != null) {
        return this.currentLogin;
      } else {
        return '';
      }
    },
  },
};
</script>
<style scoped>
.container {
  height: 100vh;
}

.auth-form {
  max-width: 450px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}

.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.user-form {
  width: 80%;
  margin-top: 20px;
}
.center {
  display: flex;
  align-items: center;
}
.header-icon {
  font-size: 60px;
  color: #047bf8;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
  margin-left: -12px;
}
.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}
</style>
